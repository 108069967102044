import React,{useState} from 'react'
import { Navbar, Dropdown, Nav, Form, Card, Container, Image, Offcanvas, CloseButton} from 'react-bootstrap'
import { Link } from 'react-router-dom'

//image
import user1 from '../../../../assets/images/user/1.jpg'
import user2 from '../../../../assets/images/user/02.jpg'
import user3 from '../../../../assets/images/user/03.jpg'
import user4 from '../../../../assets/images/user/04.jpg'
import user5 from '../../../../assets/images/user/05.jpg'
//Componets
import CustomToggle from '../../../dropdowns'

const Header = () => {
    const minisidebar =() =>{
        document.getElementsByTagName('ASIDE')[0].classList.toggle('sidebar-mini')
    }
    const [show1, setShow1] = useState(false);
    return (
        <>
            <div className="iq-top-navbar">
                <Navbar expand="lg" variant="light" className="nav iq-navbar p-lg-0">
                    <Container fluid className="navbar-inner">
                        <div className="d-flex align-items-center gap-3">
                            <Link to="/" className="d-flex align-items-center gap-2 iq-header-logo d-none d-md-flex">
                                <img src="logo48.png" className="img-fluid rounded-normal" alt="logo" with="50" />


                                <h3 className="logo-title d-none d-sm-block">Storichain</h3>    
                            </Link>
                            <Link to="#" className="sidebar-toggle" data-toggle="sidebar" data-active="true" onClick={minisidebar}>
                                <div className="icon material-symbols-outlined iq-burger-menu">
                                    menu
                                </div>
                            </Link>
                        </div>    
                        <div className="d-block d-md-none">
                            <Link to="/" className="d-flex align-items-center gap-2 iq-header-logo">
                                    <img src="logo48.png" className="img-fluid rounded-normal" alt="logo" with="50" />

                                <h3 className="logo-title d-none d-sm-block" data-setting="app_name">Storichain</h3>
                            </Link>
                        </div> 
                        <div className="iq-search-bar device-search">
                            <Form action="#" className="searchbox">
                                <Link className="search-link" to="#">
                                    <span className="material-symbols-outlined">search</span>
                                </Link>
                                <Form.Control type="text" className="text search-input bg-soft-primary" placeholder="Search here..." />
                            </Form>
                        </div>
                        <Link className="d-lg-none d-flex" to="#" aria-expanded={show1} data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" onClick={() => setShow1(!show1)} >
                            <span className="material-symbols-outlined">tune</span>
                        </Link>
                        <div className={`offcanvas offcanvas-end iq-profile-menu-responsive ${show1 === true ? 'show' : '' } `} tabIndex="-1" id="offcanvasBottom" style={{visibility: `${show1 === true ? 'visible' : 'hidden'}`}}>
                            <Offcanvas.Header>
                                <h5 id="offcanvasRightLabel">General Setting</h5>
                                <CloseButton onClick={() =>setShow1(false)} />
                                {/* <Button type="button" className="btn-close text-reset"></Button> */}
                            </Offcanvas.Header>
                            <Offcanvas.Body className="pt-0">
                                <ul className="navbar-nav  ms-auto navbar-list">
                                    <Nav.Item as="li">
                                        <Link to="/" className="d-flex align-items-center">
                                            <i className="material-symbols-outlined">home</i>
                                            <span className="mobile-text d-lg-none ms-3">Home</span>
                                        </Link>
                                    </Nav.Item>
                                    <Dropdown as="li" className="nav-item d-none d-lg-block">
                                        <Dropdown.Toggle href="/" as={CustomToggle} variant="d-flex align-items-center">
                                            <span className="material-symbols-outlined">group</span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="sub-drop sub-drop-large">
                                            <Card className="shadow-none m-0">
                                                <Card.Header className="d-flex justify-content-between bg-primary">
                                                    <div className="header-title">
                                                        <h5 className="mb-0 text-white">Friend Request</h5>
                                                    </div>
                                                </Card.Header>
                                                <Card.Body className="p-0">
                                                    <div className="iq-friend-request">
                                                        <div className="iq-sub-card iq-sub-card-big d-flex align-items-center justify-content-between">
                                                            <div className="d-flex align-items-center">
                                                            <Image className="avatar-40 rounded" src={user1} alt="" loading="lazy" />
                                                            <div className="ms-3">
                                                                <h6 className="mb-0 ">Jaques Amole</h6>
                                                                <p className="mb-0">40 friends</p>
                                                            </div>
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                                <Link to="#" className="me-3 btn btn-primary rounded">Confirm</Link>
                                                                <Link to="#" className="me-3 btn btn-secondary rounded">Delete Request</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="iq-friend-request">
                                                        <div className="iq-sub-card iq-sub-card-big d-flex align-items-center justify-content-between">
                                                            <div className="d-flex align-items-center">
                                                            <Image className="avatar-40 rounded" src={user2} alt="" loading="lazy" />
                                                            <div className="ms-3">
                                                                <h6 className="mb-0 ">Lucy Tania</h6>
                                                                <p className="mb-0">12 friends</p>
                                                            </div>
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                                <Link to="#" className="me-3 btn btn-primary rounded">Confirm</Link>
                                                                <Link to="#" className="me-3 btn btn-secondary rounded">Delete Request</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="iq-friend-request">
                                                        <div className="iq-sub-card iq-sub-card-big d-flex align-items-center justify-content-between">
                                                            <div className="d-flex align-items-center">
                                                            <Image className="avatar-40 rounded" src={user3} alt="" loading="lazy" />
                                                            <div className=" ms-3">
                                                                <h6 className="mb-0 ">Manny Petty</h6>
                                                                <p className="mb-0">3 friends</p>
                                                            </div>
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                                <Link to="#" className="me-3 btn btn-primary rounded">Confirm</Link>
                                                                <Link to="#" className="me-3 btn btn-secondary rounded">Delete Request</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="iq-friend-request">
                                                        <div className="iq-sub-card iq-sub-card-big d-flex align-items-center justify-content-between">
                                                            <div className="d-flex align-items-center">
                                                            <Image className="avatar-40 rounded" src={user4} alt="" loading="lazy" />
                                                            <div className="ms-3">
                                                                <h6 className="mb-0 ">Marsha Mello</h6>
                                                                <p className="mb-0">15 friends</p>
                                                            </div>
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                                <Link to="#" className="me-3 btn btn-primary rounded">Confirm</Link>
                                                                <Link to="#" className="me-3 btn btn-secondary rounded">Delete Request</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="text-center">
                                                        <Link to="#" className=" btn text-primary">View More Request</Link>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Nav.Item as="li" className="d-lg-none">
                                        <Link to="/dashboard/app/friend-request" className="d-flex align-items-center">
                                            <span className="material-symbols-outlined">group</span>
                                            <span className="mobile-text  ms-3">Friend Request</span>
                                        </Link>
                                    </Nav.Item>
                                    <Dropdown as="li" className="nav-item d-none d-lg-block">
                                        <Dropdown.Toggle href="#" as={CustomToggle} variant="search-toggle d-flex align-items-center">
                                            <i className="material-symbols-outlined">notifications</i>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="sub-drop">
                                            <Card className="shadow-none m-0">
                                                <Card.Header className="d-flex justify-content-between bg-primary">
                                                    <div className="header-title bg-primary">
                                                        <h5 className="mb-0 text-white">All Notifications</h5>
                                                    </div>
                                                    <small className="badge  bg-light text-dark">4</small>
                                                </Card.Header>
                                                <Card.Body className="p-0">
                                                    <Link to="#" className="iq-sub-card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="">
                                                                <Image className="avatar-40 rounded" src={user1} alt="" loading="lazy" />
                                                            </div>
                                                            <div className="ms-3 w-100">
                                                                <h6 className="mb-0 ">Emma Watson Bni</h6>
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <p className="mb-0">95 MB</p>
                                                                    <small className="float-right font-size-12">Just Now</small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                    <Link to="#" className="iq-sub-card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="">
                                                                <Image className="avatar-40 rounded" src={user2} alt="" loading="lazy" />
                                                            </div>
                                                            <div className="ms-3 w-100">
                                                                <h6 className="mb-0 ">New customer is join</h6>
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <p className="mb-0">Cyst Bni</p>
                                                                    <small className="float-right font-size-12">5 days ago</small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                    <Link to="#" className="iq-sub-card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="">
                                                                <Image className="avatar-40 rounded" src={user3} alt="" loading="lazy" />
                                                            </div>
                                                            <div className="ms-3 w-100">
                                                                <h6 className="mb-0 ">Two customer is left</h6>
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <p className="mb-0">Cyst Bni</p>
                                                                    <small className="float-right font-size-12">2 days ago</small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                    <Link to="#" className="iq-sub-card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="">
                                                                <Image className="avatar-40 rounded" src={user4} alt="" loading="lazy" />
                                                            </div>
                                                            <div className="w-100 ms-3">
                                                                <h6 className="mb-0 ">New Mail from Fenny</h6>
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <p className="mb-0">Cyst Bni</p>
                                                                        <small className="float-right font-size-12">3 days ago</small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </Card.Body>
                                            </Card>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Nav.Item as="li" className="d-lg-none">
                                        <Link to="/dashboard/app/notification" className="d-flex align-items-center">
                                            <i className="material-symbols-outlined">notifications</i>
                                            <span className="mobile-text  ms-3">Notifications</span>
                                        </Link>
                                    </Nav.Item>
                                    <Dropdown as="li" className="nav-item d-none d-lg-block">
                                        <Dropdown.Toggle href="#" as={CustomToggle} variant="d-flex align-items-center">
                                            <i className="material-symbols-outlined">mail</i>
                                            <span className="mobile-text d-lg-none ms-3">Message</span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="sub-drop">
                                            <Card className="shadow-none m-0">
                                                <Card.Header className="d-flex justify-content-between bg-primary">
                                                    <div className="header-title bg-primary">
                                                        <h5 className="mb-0 text-white">All Message</h5>
                                                    </div>
                                                    <small className="badge bg-light text-dark">4</small>
                                                </Card.Header>
                                                <Card.Body className="p-0 ">
                                                    <Link to="#" className="iq-sub-card">
                                                        <div className="d-flex  align-items-center">
                                                            <div className="">
                                                                <Image className="avatar-40 rounded" src={user1} alt="" loading="lazy" />
                                                            </div>
                                                            <div className=" w-100 ms-3">
                                                                <h6 className="mb-0 ">Bni Emma Watson</h6>
                                                                <small className="float-left font-size-12">13 Jun</small>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                    <Link to="#" className="iq-sub-card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="">
                                                                <Image className="avatar-40 rounded" src={user2} alt="" loading="lazy" />
                                                            </div>
                                                            <div className="ms-3">
                                                                <h6 className="mb-0 ">Lorem Ipsum Watson</h6>
                                                                <small className="float-left font-size-12">20 Apr</small>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                    <Link to="#" className="iq-sub-card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="">
                                                                <Image className="avatar-40 rounded" src={user3} alt="" loading="lazy" />
                                                            </div>
                                                            <div className="ms-3">
                                                                <h6 className="mb-0 ">Why do we use it?</h6>
                                                                <small className="float-left font-size-12">30 Jun</small>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                    <Link to="#" className="iq-sub-card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="">
                                                                <Image className="avatar-40 rounded" src={user4} alt="" loading="lazy" />
                                                            </div>
                                                            <div className="ms-3">
                                                                <h6 className="mb-0 ">Variations Passages</h6>
                                                                <small className="float-left font-size-12">12 Sep</small>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                    <Link to="#" className="iq-sub-card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="">
                                                                <Image className="avatar-40 rounded" src={user5} alt="" loading="lazy" />
                                                            </div>
                                                            <div className="ms-3">
                                                                <h6 className="mb-0 ">Lorem Ipsum generators</h6>
                                                                <small className="float-left font-size-12">5 Dec</small>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </Card.Body>
                                            </Card>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Nav.Item as="li" className="d-lg-none">
                                        <Link to="/dashboard/app/chat" className="dropdown-toggle d-flex align-items-center">
                                            <i className="material-symbols-outlined">mail</i>
                                            <span className="mobile-text  ms-3">Message</span>
                                        </Link>
                                    </Nav.Item>
                                    <Dropdown as="li" className="nav-item d-none d-lg-block">
                                        <Dropdown.Toggle href="#" as={CustomToggle} variant="d-flex align-items-center">
                                            <Image src={user1} className="img-fluid rounded-circle me-3" alt="user" loading="lazy" />
                                            <div className="caption">
                                              <h6 className="mb-0 line-height">Bni Cyst</h6>
                                            </div>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="sub-drop caption-menu">
                                            <Card className="shadow-none m-0">
                                                <Card.Header>
                                                    <div className="header-title">
                                                        <h5 className="mb-0 ">Hello Bni Cyst</h5>
                                                    </div>
                                                </Card.Header>
                                                <Card.Body className="p-0 ">
                                                    <div className="d-flex align-items-center iq-sub-card border-0">
                                                        <span className="material-symbols-outlined">
                                                            line_style
                                                        </span>
                                                        <div className="ms-3">
                                                            <Link to="/dashboard/app/profile" className="mb-0 h6">
                                                                My Profile
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center iq-sub-card border-0">
                                                        <span className="material-symbols-outlined">
                                                            edit_note
                                                        </span>
                                                        <div className="ms-3"> 
                                                            <Link to="#" className="mb-0 h6">
                                                                Edit Profile
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center iq-sub-card border-0">
                                                        <span className="material-symbols-outlined">
                                                            manage_accounts
                                                        </span>
                                                        <div className="ms-3">
                                                            <Link to="/dashboard/app/user-account-setting" className="mb-0 h6">
                                                            Account settings
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center iq-sub-card border-0">
                                                        <span className="material-symbols-outlined">
                                                            lock
                                                        </span>
                                                        <div className="ms-3">  
                                                            <Link to="/dashboard/app/user-privacy-setting" className="mb-0 h6">
                                                            Privacy Settings
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center iq-sub-card">
                                                        <span className="material-symbols-outlined">
                                                            login
                                                        </span>
                                                        <div className="ms-3">  
                                                            <Link to="/auth/sign-in" className="mb-0 h6">
                                                            Sign out
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div className=" iq-sub-card">
                                                        <h5>Chat Settings</h5>
                                                    </div>
                                                    <div className="d-flex align-items-center iq-sub-card border-0">
                                                        <i className="material-symbols-outlined text-success md-14">
                                                            circle
                                                        </i>  
                                                        <div className="ms-3"> 
                                                            Online
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center iq-sub-card border-0">
                                                        <i className="material-symbols-outlined text-warning md-14">
                                                            circle
                                                        </i>                                            
                                                        <div className="ms-3">
                                                            Away
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center iq-sub-card border-0">
                                                        <i className="material-symbols-outlined text-danger md-14">
                                                            circle
                                                        </i>                                             
                                                        <div className="ms-3">
                                                            Disconnected
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center iq-sub-card border-0">
                                                        <i className="material-symbols-outlined text-gray md-14">
                                                            circle
                                                        </i>                                              
                                                        <div className="ms-3">
                                                            Invisible
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Nav.Item as="li" className="d-lg-none">
                                        <Link to="/dashboard/app/profile" className="dropdown-toggle d-flex align-items-center">
                                            <span className="material-symbols-outlined">person</span>
                                            <span className="mobile-text  ms-3">Profile</span>
                                        </Link>
                                    </Nav.Item>
                                </ul>
                            </Offcanvas.Body>
                        </div>
                    </Container>
                </Navbar>
            </div>
        </>
    )
}

export default Header
